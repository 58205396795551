module.exports = {
  content: [
    'app/**/*.html.*',
    'app/components/**/*.rb',
    'app/helpers/*.rb',
    'app/models/typography.rb',
  ],
  theme: {
    boxShadow: {
      branding: '-16px 12px rgba(0, 0, 0, .2)',
    },
    fontFamily: {
      archivo: ['Archivo', 'sans-serif'],
      sans: ['Plus Jakarta Sans', 'sans-serif'],
    },
    colors: {
      primary: '#4D67EA', // jetpack-workflow-primary-blue
      'primary-darkened': '#1C3BD6', // Custom, derived from jetpack-workflow-blue
      'primary-light': '#D1D9FF', // Custom, derived from jetpack-workflow-lightest-blue
      state: {
        // To be used in situations where color has meaning.
        // Darkened variations to be used for small non-bold text
        warning: '#EB7207', // Custom, derived from jetpack-workflow-yellow
        inprogress: '#EB7207', // Custom, derived from jetpack-workflow-yellow
        'success-light': '#F4FFFC',
        success: '#008262', // jetpack-workflow-green
        'success-darkened': '#027055', // Custom, derived from jetpack-workflow-green
        danger: '#F04747', // Custom, derived from jetpack-workflow-coral
        'danger-darkened': '#D60909', // Custom, derived from jetpack-workflow-coral
        next: '#714BB2', // jetpack-workflow-purple
        beta: '#EB7207',
      },
      grayscale: {
        white: '#FFFFFF', // White
        50: '#F5F7FA', // Custom, derived from jetpack-workflow-light-gray
        100: '#E9EDF5', // Custom, derived from jetpack-workflow-light-gray
        200: '#D1D9E5', // Custom, derived from jetpack-workflow-light-gray
        300: '#A3B2C9', // jetpack-workflow-light-gray
        500: '#6D7787', // jetpack-workflow-gray
        600: '#5D6675',
        700: '#444A54', // jetpack-workflow-dark-gray
        800: '#393C40',
        black: '#23262B', // jetpack-workflow-black
      },
      nav: {
        900: '#1C3BD6', // Custom, derived from jetpack-workflow-blue
        700: '#4D67EA', // jetpack-workflow-blue
        100: '#F6F6F6', // jetpack-workflow-super-light-gray
      },
      transparent: 'transparent',
    },
  },
  plugins: [
    require('@tailwindcss/forms'), /* eslint-disable-line global-require */
    require('tailwind-scrollbar-hide'), /* eslint-disable-line global-require */
  ],
};
