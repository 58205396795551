import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    skipClearMember: Boolean,
  };

  initialize() {
    this.id = this.element.dataset.id;
    this.type = this.element.dataset.type;
    if (this.element.dataset.ids) {
      this.ids = JSON.parse(this.element.dataset.ids.replace(/'/g, '"'));
    } else {
      this.ids = [];
    }
  }

  connect() {
    const buttons = document.querySelectorAll(`button[data-id="${this.id}"][data-type="${this.type}"]`);
    buttons.forEach((button) => {
      button.addEventListener('mouseenter', (event) => this.handleHover(event));
      button.addEventListener('mouseleave', (event) => this.handleHover(event));
    });
  }

  handleHover(event) {
    const buttons = document.querySelectorAll(`button[data-id="${this.id}"][data-type="${this.type}"]`);
    buttons.forEach((button) => {
      if (event.type === 'mouseenter') {
        if (button.classList.contains('is-current-week')) {
          button.classList.replace('bg-opacity-30', 'bg-opacity-50');
        } else {
          button.classList.replace('bg-grayscale-white', 'bg-grayscale-50');
        }
      } else if (event.type === 'mouseleave') {
        if (button.classList.contains('is-current-week')) {
          button.classList.replace('bg-opacity-50', 'bg-opacity-30');
        } else {
          button.classList.replace('bg-grayscale-50', 'bg-grayscale-white');
        }
      }
    });
  }

  toggleExpandablePlanningAllocation(_event) {
    this.collapseIcon = document.getElementById(`collapse-icon-${this.id}`);
    this.expandIcon = document.getElementById(`expand-icon-${this.id}`);
    const buttons = document.querySelectorAll(`button[data-id="${this.id}"]`);

    this.toggleClasses();

    if (this.isExpandingTeamMember()) {
      this.fireTurboRequestToLoadClients();
      buttons.forEach((button) => {
        button.classList.add('sticky', 'top-0');
        if (button.classList.contains('is-current-week')) {
          button.classList.remove('bg-opacity-30', 'bg-opacity-50');
        }
      });
    } else {
      this.removeClientsFromPage();
      buttons.forEach((button) => {
        button.classList.remove('sticky', 'top-0');
        if (button.classList.contains('is-current-week')) {
          button.classList.add('bg-opacity-50');
        }
      });
    }
  }

  toggleClasses() {
    this.collapseIcon.classList.toggle('hidden');
    this.expandIcon.classList.toggle('hidden');
  }

  isExpandingTeamMember() {
    return this.expandIcon.classList.contains('hidden');
  }

  fireTurboRequestToLoadClients() {
    const loadClientTurboLink = document.getElementById(`load-clients-for-member-${this.id}`);
    if (loadClientTurboLink) loadClientTurboLink.click();
  }

  removeClientsFromPage() {
    this.eraseClientNames();
    this.eraseClientBudgets();
    this.eraseClientTotals();
    if (this.skipClearMemberValue === false) {
      this.clearSelectedMember();
    }
  }

  removeClientsFromPageForAllMembers() {
    const { skipClearMember } = this.element.dataset;
    this.ids.forEach((id) => {
      this.eraseClientNames(id);
      this.eraseClientBudgets(id);
      this.eraseClientTotals(id);
      if (skipClearMember !== 'true') {
        this.clearSelectedMember(id);
      }
    });
  }

  eraseClientNames(id = this.id) {
    const clients = document.getElementById(`clients-for-team-member-${id}`);
    if (clients) clients.innerHTML = '';
  }

  clearSelectedMember(id = this.id) {
    const clearSelectedMemberTurboLink = document.getElementById(`clear-selected-member-${id}`);
    if (clearSelectedMemberTurboLink) clearSelectedMemberTurboLink.click();
  }

  eraseClientBudgets(id = this.id) {
    const clientBudgets = document.getElementsByClassName(`client-budget-for-member-${id}`);

    Array.from(clientBudgets).forEach((clientBudget) => {
      // eslint-disable-next-line no-param-reassign
      clientBudget.innerHTML = '';
    });
  }

  eraseClientTotals(id = this.id) {
    const clientTotals = document.getElementById(`client-budget-total-member-${id}`);
    if (clientTotals) clientTotals.innerHTML = '';
  }
}
